/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/JRPresentation/sections/Counters";
import Information from "pages/JRPresentation/sections/Information";
// import DesignBlocks from "pages/JRPresentation/sections/DesignBlocks";
// import Pages from "pages/JRPresentation/sections/Pages";
import Testimonials from "pages/JRPresentation/sections/Testimonials";
import Download from "pages/JRPresentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/JRPresentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/rowers3.jpg";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://app.justrow.co.uk",
          label: "Login / Signup Now!",
          color: "primary",
        }}
        sticky
      />
      <MKBox
        minHeight="97vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={4} lg={8} justifyContent="center" mx="auto">
            <MKBox bgColor="black" opacity={0.9} borderRadius="xl" justifyContent="center">
              <MKTypography
                variant="h1"
                color="white"
                textAlign="center"
                my={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Just Row
              </MKTypography>
              <MKTypography
                variant="body1"
                color="primary"
                textAlign="center"
                px={{ xs: 1, lg: 12 }}
                mx={{ xs: 1, lg: 12 }}
                my={2}
              >
                An anonymous 2k tracker for rowers and captains ...for happier + more inclusive
                rowing.
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Container sx={{ mt: 3, mb: 6 }}>
          <BuiltByDevelopers />
        </Container>
        {/* <DesignBlocks /> */}
        {/* <Pages /> */}
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="primary"
                icon="flag"
                title="For captains"
                description="Captains and coaches have access to the full team view, and can see 2ks for each member of their squad. They can also be alerted to a team member who may need support by feedback and comments the rower may include with their submission."
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                //   label: "Let's start",
                // }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="primary"
                icon="flag"
                title="For rowers"
                description="Keep track of your 2ks and how you were feeling at the time. Submit 2ks anonymously to your team page, so that you can see where you stand amongst your teammates, but without the social pressure. Captains and coaches will be able to see individual results."
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                //   label: "Read more",
                // }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="primary"
                icon="flag"
                title="For anyone"
                description="Is the rowing ergometer a key component of your fitness regime? Monitor your health and fitenss by keeping track of your performance and how you felt at the time with Just Row! Is there a 2k leaderboard at your gym? Keep it fun and anonymous by using Just Row!"
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                //   label: "Read more",
                // }}
              />
            </Grid>
          </Grid>
        </Container>
        <Information />
        <Testimonials />
        <Download />
        <MKBox pb={3}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                {/* <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography> */}
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.justrow.co.uk"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  {/* <i className="fab fa-twitter" /> */}
                  &nbsp;X
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.justrow.co.uk"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  &nbsp;FB
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
