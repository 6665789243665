/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
// import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
// import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
// import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
// import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
// import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

function Information() {
  return (
    <MKBox component="section" pt={4}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          {/* <MKTypography variant="h2">Trusted by over</MKTypography> */}
          <MKTypography variant="h2" color="primary" textGradient>
            ❤️
          </MKTypography>
          <MKTypography variant="body1" color="primary">
            Many elite universities, colleges, club teams, and gyms around the globe love{" "}
            <strong>Just Row</strong>.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Nick Willever"
              date="1 day ago"
              review="This is an excellent product, it's really put the power back into my own hands. 2ks used to make me feel threatened, embarassed, and anxious. I now feel more in control."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="light"
              name="Charlotte Meijer"
              date="1 week ago"
              review="After pleading with rowers for years, even losing novices outright, we have FINALLY found a solution to the '2k problem.' Everyone loves to see where they stand, but not everyone wants their name associated with their rank! This app has been such a relief for the many on our team who love rowing, love the team, are supportive and humble and kind, but who just don't want their 2k score set in stone for all to see. They can still do the test, securely submit their result, and we can go about setting boats. We've also been able to put our Welfare officer in touch with members who weren't feeling great about the group dynamic...which we would not have known were it not for the feedback option here. Just Row is a godsend for coaches and captains. Thanks guys!!!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Lily Smith-Withers"
              date="3 weeks ago"
              review="Really love this so much. I love rowing and I even love erging, but at 2k test time it would all fall apart. The team got gossipy, everyone was simply avoiding each other and the erg test, the coaches were upset...it was a mess. To make matters worse you always had to send a screen shot of the erg to the captains. It was just all sketchy and weird and deeply unpleasant.Just Row changed all that."
              rating={5}
            />
          </Grid>
        </Grid>
        {/* <Divider sx={{ my: 6 }} /> */}
        {/* <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={appleLogo} alt="Apple" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={facebookLogo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={nasaLogo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={vodafoneLogo} alt="Vodafone" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={digitalOceanLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid>
        </Grid> */}
      </Container>
    </MKBox>
  );
}

export default Information;
