/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com
 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
// import bgFront from "assets/images/rotating-card-bg-front.jpeg";
// import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                // image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Manage 2ks for yourself + for your team
                    <br />
                  </>
                }
                description="The tool you need to keep track of your progress, the team's progress, and the team's wellbeing."
              />
              <RotatingCardBack
                // image={bgBack}
                title="Row better"
                description="2ks are painful enough without the pscyhological and social pressures of competition."
                action={{
                  type: "external",
                  route: "https://app.justrow.co.uk",
                  label: "create account",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="mood"
                  title="Improve mental health + wellbeing"
                  description="Reduce the psychological ill effects of competition and the anxiety and social pressure to perform well. Keep team camaraderie intact!"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="star"
                  title="Get feedback"
                  description="Comes with an inbuilt feedback tool so that you can monitor your team wellbeing and have your team welfare officer reach out privately."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="diversity_3"
                  title="Foster social justice"
                  description="Increase participation and reduce barriers to entry for groups who may already be marginalized and face obstacles to joining the sport."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="sort"
                  title="Track stats"
                  description="Teammates can see where they stand -- anonymously! -- within the team; captains and coaches have full visibility for boat planning."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
